import styles from './css/MenuItems.module.css';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import IconContainer from '../../components/iconContainer/IconContainer';
import Accordion from './Accordion';
import { getUserPermissions } from '../../api/authentication';
import { Skeleton } from '@mui/material';

const MenuItems = ({ items, isMenuOpen }) => {
  const {
    data: role,
    isLoading,
    isError,
  } = useQuery({
    queryFn: () => getUserPermissions(),
    queryKey: ['logged-in-user-permissions'],
  });
  const roleName = role?.name;
  const permissions = role?.permissions;
  const [cookies] = useCookies(['tenantId']);
  const tenantId = cookies.tenantId; // Get the tenantId of the logged in user
  const digitalFingerprintTenantId =
    process.env.REACT_APP_DIGITAL_FINGERPRINT_TENANT_ID; // Get the tenantId of Digital Fingerprint
  const location = useLocation();

  const activeLink = items.find(
    (link) =>
      location.pathname === link.path ||
      link?.otherPaths?.includes(location.pathname)
  );

  const itemsWithoutPermissionCheck = [
    // 'Find Tenders',
    'Home',
    'Dashboards',
    'Email',
    'Tenants',
    'Tenant Plans',
    'Profile',
    'Standard Charts',
    'Manage Users',
    'Parameters',
    'Roles And Permissions',
  ]; // Add item names that don't require permission checks

  // items for admins and superusers only
  const adminOnlyItems = [
    'Standard Charts',
    'Manage Users',
    'Parameters',
    'Roles And Permissions',
  ];

  if (isLoading) {
    return (
      <ul className={styles['sidebar-items']}>
        {Array.from(new Array(5)).map((_, index) => (
          <li
            key={index}
            className={
              isMenuOpen
                ? styles['skeleton-item-menu-open']
                : styles['skeleton-item-menu-closed']
            }
          >
            <Skeleton
              sx={{ borderRadius: '4px' }}
              variant="rectangular"
              width={isMenuOpen ? '20%' : '100%'}
              height={40}
            />
            {isMenuOpen && (
              <Skeleton
                sx={{ borderRadius: '4px' }}
                variant="rectangular"
                width={90}
                height={20}
              />
            )}
          </li>
        ))}
      </ul>
    );
  }

  if (isError) {
    return <p>Error loading permissions</p>;
  }

  return (
    <ul className={styles['sidebar-items']}>
      {items
        .filter((listItem) => {
          // Exclude "Tenants" and "Tenant Plans" items if tenantId doesn't match
          if (
            (listItem.name === 'Tenants' || listItem.name === 'Tenant Plans') &&
            tenantId !== digitalFingerprintTenantId
          ) {
            return false;
          }

          // Items that can only be viewed by Superusers, and, or Admins
          if (
            adminOnlyItems.includes(listItem.name) &&
            roleName !== 'Superuser' &&
            roleName !== 'Admin'
          ) {
            return false;
          }

          // Always include items that don't require permission checks
          if (itemsWithoutPermissionCheck.includes(listItem.name)) {
            return true;
          }

          // Check permissions
          if (permissions) {
            const permissionKey = listItem.name.toLowerCase();
            return permissions[permissionKey]?.view;
          }

          return false;
        })
        .map((listItem, index) =>
          listItem.isAccordion ? (
            <Accordion
              key={index}
              icon={listItem.icon}
              title={listItem.name}
              options={listItem.options}
              isMenuOpen={isMenuOpen}
            />
          ) : (
            <li
              key={index}
              title={!isMenuOpen ? listItem.name : ''}
              className={styles['sidebar-list-items']}
            >
              <Link
                to={listItem.path}
                className={
                  activeLink && activeLink.path === listItem.path
                    ? styles['active']
                    : ''
                }
                style={isMenuOpen ? {} : { display: 'flex' }} // sidebarCollapsed is undefined, so changed to an empty object if menu is open
              >
                <IconContainer
                  icon={listItem.icon}
                  isActive={activeLink && activeLink.path === listItem.path}
                />
                {isMenuOpen && (
                  <p className={styles['text']}>{listItem.name}</p>
                )}
              </Link>
            </li>
          )
        )}
    </ul>
  );
};

export default MenuItems;
